import React from "react";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./Partners.css";

const images = Array.from({ length: 22 }, (_, i) =>
  require(`../../assets/partner/${i + 1}.png`)
);

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};

const PartnersNITS = () => {
  return (
    <div className="container-fluid mainOuterPartnter">
      <div className="row">
        <div className="col-lg-12">
          <div className="">
            <Carousel
              swipeable={true}
              draggable={true}
              showDots={false}
              responsive={responsive}
              ssr={true}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={1000}
              keyBoardControl={true}
              customTransition="transform 1.2s ease"
              transitionDuration={500}
              containerClass="carousel-container slide-animation"
              removeArrowOnDeviceType={["desktop, tablet", "mobile"]}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
            >
           {images.map((img, index) => (
              <img key={index} src={img} alt={`Partner ${index + 1}`} className="imagePartner" />
            ))}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnersNITS;

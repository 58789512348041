import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Homepage from "./Screens/Homepage";
import AllServices from "./Screens/AllServices";
import Blogs from "./Screens/Blogs";
import Offers from "./Screens/Offers";
import GetInTouch from "./Screens/GetInTouch";
import Navbar from "./Components/Navbar/Navbar";
import StripNav from "./Components/StripNav/StripNav";
import Footer from "./Components/Footer/Footer";
import BlogDetails from "./Screens/BlogDetails";
import BusinessApplication from "./Screens/AllCategory/BusinessApplication";
import VoiceDataSolutions from "./Screens/AllCategory/VoiceDataSolutions";
import CyberSecuritySolutions from "./Screens/AllCategory/CyberSecuritySolutions";
import CollaborationSolutions from "./Screens/AllCategory/CollaborationSolutions";
import SalesForceAutomation from "./Screens/AllCategory/SalesForceAutomation";
import SingleServices from "./Screens/SingleServices";
import HRSoftware from "./Screens/AllCategory/HRSoftware";

function App() {
  return (
    <Router>
      <div className="App">
        <StripNav />
        <Navbar />
        <Switch>
          <Route exact path="/">
            <Homepage />
          </Route>
          <Route exact path="/all-services">
            <AllServices />
          </Route>
          <Route exact path="/blogs">
            <Blogs />
          </Route>
          <Route exact path="/offers">
            <Offers />
          </Route>
          <Route exact path="/get-in-touch">
            <GetInTouch />
          </Route>
          <Route exact path="/blog/:id">
            <BlogDetails />
          </Route>
          <Route exact path="/business-applications">
            <BusinessApplication />
          </Route>
          <Route exact path="/collaboration-solutions">
            <CollaborationSolutions />
          </Route>
          <Route exact path="/cyber-security-solutions">
            <CyberSecuritySolutions />
          </Route>
          <Route exact path="/sales-force-automation">
            <SalesForceAutomation />
          </Route>
          <Route exact path="/voice-data-solution">
            <VoiceDataSolutions />
          </Route>
          <Route exact path="/hr-software">
            <HRSoftware />
          </Route>
          <Route exact path="/all-services/:id">
            <SingleServices />
          </Route>
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;

import React from "react";
import "./AllservicesBanner.css";

const AllServicesBanner = () => {
    
  return (
    <div className="hero-image111111">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="hero-text_12">
              <h1> Elevate Your Business with Exclusive Software Solutions!</h1>
              <p>
               
                Discover the key to unlocking unparalleled business growth with
                National IT Services. Our extensive selection of premium
                business software comes with unbeatable discounts, empowering
                you to streamline operations and boost productivity. Explore a
                world of possibilities with top-tier vendors, all at prices that
                will amaze you. Welcome to a new era of efficiency and success –
                seize the moment with National IT Services!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllServicesBanner;

import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import DOMPurify from "dompurify";
import blogsData from "../Components/HomePageBlog/blog";
import "../assets/style/BlogDetails.css";

const BlogDetails = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { id } = useParams();
  const blogId = parseInt(id);

  const blog = blogsData.find((blog) => blog.id === blogId);

  if (!blog) {
    return <p>Blog not found</p>;
  }
  const htmlContent = DOMPurify.sanitize(blog.content);
  const truncateText = (text, numWords) => {
    const words = text.split(" ");
    if (words.length <= numWords) {
      return text;
    }
    return words.slice(0, numWords).join(" ") + "...";
  };
  const truncatedTitle = truncateText(blog.title, 4);

  return (
    <>
      <div className="breadcrumbs">
        <div className="pBD">
          <p className="ppBD">{truncatedTitle}</p>
        </div>

        <div>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/blogs">Blog</Link>
          </li>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-9">
            <h2 className="mainDetailBlogTitle">{blog.title}</h2>

            <img
              src={blog.imageSrc}
              alt="Blog"
              className="img-fluid mainDetailBlogImage"
            />

            <h1 className="mainDetailBlogIntro">{blog.intro}</h1>

            <div
              className="mainDetailBlogContent"
              dangerouslySetInnerHTML={{ __html: htmlContent }}
            />
          </div>

          <div className="col-lg-3 mainSideBlogBar">
            <div>
              <h1 className="rmblgiur">Read More Blogs&nbsp;</h1>
            </div>

            {blogsData.slice(0, 3).map((latestBlog) => (
              <div className="card" key={latestBlog.id}>
                <div className="latest-blog-item">
                  <Link to={`/blog/${latestBlog.id}`} className="sideLink">
                    <img
                      src={latestBlog.imageSrc}
                      alt={latestBlog.title}
                      className="card-img-top___2 img-fluid"
                    />
                  </Link>

                  <div className="card-body">
                    <Link to={`/blog/${latestBlog.id}`} className="sideLink">
                      <h5 className="side_blog_title">{latestBlog.title}</h5>
                    </Link>

                    <Link to={`/blog/${latestBlog.id}`} className="sideLink">
                      <p className="side_blog_content">
                        {latestBlog.intro.slice(0, 90)}
                        {latestBlog.intro.length > 90 && "..."}
                      </p>
                    </Link>

                    <Link to={`/blog/${latestBlog.id}`} className="sideLink">
                      <button className="side_blog_button">Read More</button>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogDetails;

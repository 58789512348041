import React from "react";
import AllCategoriesBanner from "../../Components/All Categories Components/All Categories Banner/AllCategoriesBanner";
import AllCategoriesSingleGrid from "../../Components/All Categories Components/All Categories Single Grid/AllCategoriesSingleGrid";
import AllServices from "../../Components/All Services Components/All Services Data/AllServicesData";

const CollaborationSolutions = ({}) => {
  const bannerPara =
    "Unlock seamless communication and collaboration across your organization with the leading software platforms from National IT Services. Our suite of powerful tools like Zoom, GoToMeeting, Yealink, TeamViewer, Zoho Workdrive, Google Workspace, and Microsoft 365 will revolutionize the way your teams connect and work together. Boost productivity, improve project management, and empower employee engagement with easy-to-use, feature-rich solutions for video conferencing, screen sharing, file storage, and more.";
  const bannerHeading = "Empower Teams with Intelligent Collaboration Tools";
  const Services = AllServices.filter(service => service.category === "Collaboration");
  return (
    <>
      <AllCategoriesBanner
        bannerPara={bannerPara}
        bannerHeading={bannerHeading}
      />
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ServicesSingleGrid-main-heading-div">
              <h1>Collaboration Solutions</h1>
              <p>
                Discover a wide range of services tailored to meet your needs.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <AllCategoriesSingleGrid Services={Services}/>
          </div>
        </div>
      </div>
    </>
  );
};

export default CollaborationSolutions;

import React from "react";
import "./BannerComponent.css";
import { Link } from "react-router-dom";

const HeroBanner = () => {
  return (
    <>
      <div className="main">
        <div className="videoBg">
        <iframe
            src="https://www.youtube.com/embed/cR1FyHv_rJE?autoplay=1&mute=1&loop=1&playlist=cR1FyHv_rJE&controls=0&showinfo=0&rel=0&modestbranding=1&iv_load_policy=3&playsinline=1&enablejsapi=1"
            title="background video"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <div className="overlay_378"></div>
        <div className="container contentMainDiv">
          <div className="row">
            <div className="col-md-7 align-self-center">
              <span className="labelTop">National IT Services</span>
              <h1 className="__cvi">
                NITS Helps You to Buy Right Software for Your Business Growth
              </h1>
              <h6 className="assubtitle__23d">
                Enterprise Grade Information & Communications Solutions. Trusted
                Partner for Telecom, Security, Backup, CRM, Cloud Hosting & GST
                Compliance solutions.
              </h6>
              <Link
                className="btn btn-light btn-rounded btn-md m-t-20"
                to="/get-in-touch"
              >
                <span>Do you Need Help?</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroBanner;
import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className=" text-light">
      <div className="container py-4">
        <div className="row">
          <div className="col-md-4">
            <h3 className="black-text_g3">Company Name</h3>
            {/* <p className="black_text____p">
              Address: 7718, 7th Floor, Vipul Plaza,
              <br />
              Sector 81, Faridabad, Haryana 121002
            </p> */}
            <p className="black_text____p">Email: info@itforsme.in</p>
            <p className="black_text____p">Phone: +91 98119 98370</p>
          </div>
          <div className="col-md-4">
            <h3 className="black-text_g3">Quick Links</h3>
            <ul className="list-unstyled">
              <li>
                <Link className="black-text" to="/all-services">
                  All Services
                </Link>
              </li>

              <li>
                <Link className="black-text" to="/offers">
                  Offers
                </Link>
              </li>
              <li>
                <Link className="black-text" to="/blogs">
                  Blogs
                </Link>
              </li>
              <li>
                <Link className="black-text" to="/get-in-touch">
                  Get In Touch
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-md-4">
            <h3 className="black-text_g3">Connect with Us</h3>
            <p className="black-text">
              Follow us on social media for updates and news.
            </p>
            <ul className="list-unstyled social-icons">
              <li>
                <a
                  href="https://www.facebook.com/nationalitservices022"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-facebook socilafooter_icon_1"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/NationalItServ2"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i class="fa-brands fa-square-x-twitter socilafooter_icon_2"></i>

                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/65726297/admin/feed/posts/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-linkedin socilafooter_icon_3"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/national_it_services/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-instagram socilafooter_icon_4"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="text-center kki">
        &copy; {new Date().getFullYear()} National IT Services. All rights
        reserved.
      </div>
    </footer>
  );
};

export default Footer;

import React, { useEffect, useState } from "react";
import "./AllCategoriesSingleGrid.css"
import { Link } from "react-router-dom";

const AllCategoriesSingleGrid = ({Services}) => {
  const blogsPerPage = 12;
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * blogsPerPage;
  const endIndex = startIndex + blogsPerPage;
  const currentBlogs = Services.slice(startIndex, endIndex);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);
  return (
    <>
      <div className="container productContainer">
        <div className="row">
          {currentBlogs.map((product, index) => (
            <div className="col-lg-3" key={index}>
              <Link to={`/all-services/${product.id}`} className="read-more">
                <div className="product">
                  <div className="product-inside">
                    <div className="image">
                      <img
                        src={product.imageSrc}
                        alt={product.title}
                        className="img-fluid allservicesProductImage"
                      />
                    </div>
                    <div className="infopro">
                      <h3 className="productName">{product.productName}</h3>
                      <p className="productBy">By {product.productCompany}</p>
                    </div>
                  </div>
                  <div className="rating text-center">
                    <div className="stars">
                      {Array.from({ length: product.rating }).map((_, i) => (
                        <i key={i} className="fas fa-star filled"></i>
                      ))}
                      {Array.from({ length: 5 - product.rating }).map(
                        (_, i) => (
                          <i key={i} className="fas fa-star"></i>
                        )
                      )}
                    </div>
                    {product.isNewFeature && (
                      <div className="badge badge-primary">New Feature</div>
                    )}
                  </div>
                  {/* <br />
                  <div className="price-cart">
                    <span className="price">Price :</span>
                    <span className="priceName">₹{product.price}</span>
                  </div>
                  <br /> */}
                  <button className="btn btnDemo btn-block">More Info</button>
                  <br />
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
      <div className="pagination">
        {currentPage > 1 && (
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            className="pagination-button skd"
          >
            <i className="fa fa-angle-left"></i> Prev
          </button>
        )}
        {Array.from(
          { length: Math.ceil(Services.length / blogsPerPage) },
          (_, index) => (
            <button
              key={index + 1}
              className={
                index + 1 === currentPage
                  ? "pagination-button active"
                  : "pagination-button"
              }
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </button>
          )
        )}
        {currentPage < Math.ceil(Services.length / blogsPerPage) && (
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            className="pagination-button"
          >
            Next <i className="fa fa-angle-right"></i>
          </button>
        )}
      </div>
    </>
  );
};

export default AllCategoriesSingleGrid;

import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./Navbar.css";
import logo from "../../assets/National-it-services-logo.png";
import pdf from "../../assets/NITS_Company_Profile.pdf";

const Navbar = () => {
  const location = useLocation();

  return (
    <div>
      <nav className="navbar navbar-expand-lg bg-body-tertiary navbar-cus">
        <div className="container-fluid">
          <Link to="/" className="navbar-brand">
            <img src={logo} alt="nationalitserviceslogo" className="nitsLogo" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item dropdown">
                <Link
                  to="/all-categories"
                  className="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  All Categories
                </Link>
                <div className="dropdown-menu">
                  <Link
                    to="/business-applications"
                    className={`dropdown-item ${
                      location.pathname === "/business-applications"
                        ? "active"
                        : ""
                    }`}
                  >
                    <i className="fa-solid fa-angles-right"></i> &nbsp; Business
                    Applications
                  </Link>
                  <Link
                    to="/voice-data-solution"
                    className={`dropdown-item ${
                      location.pathname === "/voice-data-solution"
                        ? "active"
                        : ""
                    }`}
                  >
                    <i className="fa-solid fa-angles-right"></i> &nbsp; Voice
                    Data Solutions
                  </Link>
                  <Link
                    to="/cyber-security-solutions"
                    className={`dropdown-item ${
                      location.pathname === "/cyber-security-solutions"
                        ? "active"
                        : ""
                    }`}
                  >
                    <i className="fa-solid fa-angles-right"></i> &nbsp; Cyber
                    Security Solutions
                  </Link>
                  <Link
                    to="/collaboration-solutions"
                    className={`dropdown-item ${
                      location.pathname === "/collaboration-solutions"
                        ? "active"
                        : ""
                    }`}
                  >
                    <i className="fa-solid fa-angles-right"></i> &nbsp;
                    Collaboration Solutions
                  </Link>
               
                  <Link
                    to="/sales-force-automation"
                    className={`dropdown-item ${
                      location.pathname === "/sales-force-automation"
                        ? "active"
                        : ""
                    }`}
                  >
                    <i className="fa-solid fa-angles-right"></i> &nbsp; Sales
                    Force Automation
                  </Link>

                  {/* <Link
                    to="/hr-software"
                    className={`dropdown-item ${
                      location.pathname === "/hr-software"
                        ? "active"
                        : ""
                    }`}
                  >
                    <i className="fa-solid fa-angles-right"></i> &nbsp; HR Software
                  </Link> */}
                </div>
              </li>
              <li className="nav-item">
                <Link to="/all-services" className="nav-link">
                  All Services
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/blogs" className="nav-link">
                  Blogs
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/offers" className="nav-link">
                  Offers
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/get-in-touch" className="nav-link">
                  Get in Touch
                </Link>
              </li>
            </ul>

            <a href={pdf} download="National-IT_Services">
              <button className="profileButton">Company Profile</button>
            </a>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;

import React from "react";
import "./StaticBanner.css";
import staticImageOffer from "../../../assets/offers/discount-im.jpg";

const StaticBanner = () => {
  return (
    <>
      <div className="container ">
        <div className="row">
          <div className="col-lg-12">
            <div className="staticOfferInnerDiv">
              <div className="imgDivSOffer">
                <img src={staticImageOffer} alt="asd" className="staticLogo" />
              </div>
              <div className="offerStaticContentDiv">
                <h3 className="offerStaticH3">
                  NITS Software Deals & Discounts
                </h3>
                <p className="offerStaticp">
                  National IT Services Software Deals & Discounts Ready to speed
                  up your business growth? We know what exactly you need! <br/> Buy
                  business software at special discounts only at National IT
                  Services. Avail the best software deals from top vendors at
                  stunning prices.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StaticBanner;

import React from "react";
import "./StripNav.css";

const StripNav = () => {
  return (
    <div className="top-strip">
      <div className="topSS">
        <span className="contact-info">
          <i className="fa-solid fa-phone"></i> 98119 98370
        </span>
        <span className="email-info">
          <i className="fas fa-envelope"></i> info@itforsme.in
        </span>
        <span className="location-info">
        <i className="fa-solid fa-location-pin"></i>
        Faridabad- HR, India</span>
      </div>
      <div>
        <a href="https://www.instagram.com/national_it_services/" target="_blank" rel="noreferrer">
          <i className="fab fa-instagram" />
        </a>
        <a href="https://www.facebook.com/nationalitservices022" target="_blank" rel="noreferrer">
          <i className="fab fa-facebook" />
        </a>
        <a href="https://www.linkedin.com/company/65726297/admin/feed/posts/" target="_blank" rel="noreferrer">
          <i className="fab fa-linkedin" />
        </a>
        <a href="https://twitter.com/NationalItServ2" target="_blank" rel=" noreferrer">
          <i className="fab fa-twitter" />
        </a>
      </div>
    </div>
  );
};

export default StripNav;

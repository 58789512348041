import React from "react";
import AllCategoriesBanner from "../../Components/All Categories Components/All Categories Banner/AllCategoriesBanner";
import "../../assets/style/allCategories.css";
import AllCategoriesSingleGrid from "../../Components/All Categories Components/All Categories Single Grid/AllCategoriesSingleGrid";
import AllServices from "../../Components/All Services Components/All Services Data/AllServicesData";

const BusinessApplication = () => {
  const bannerPara =
    "Streamline your operations and unlock new levels of productivity with our suite of cutting-edge business software solutions. With National IT Services, you can optimize workflows, track detailed analytics, and manage payroll, accounting, HR, and more - all from one integrated platform. We're your one-stop shop for unlocking the full potential of your organization. Seize the moment and discover what National IT Services' business application solutions can do for you today!";
  const bannerHeading =
    "Streamline Operations with Intelligent Business Applications";
  const Services = AllServices.filter(
    (service) => service.category === "Business"
  );
  return (
    <>
      <AllCategoriesBanner
        bannerPara={bannerPara}
        bannerHeading={bannerHeading}
      />
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ServicesSingleGrid-main-heading-div">
              <h1>Business Application Solutions</h1>
              <p>
                Discover a wide range of services tailored to meet your needs.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <AllCategoriesSingleGrid Services={Services} />
          </div>
        </div>
      </div>
    </>
  );
};

export default BusinessApplication;

import React, { useEffect } from "react";
import AllServicesBanner from "../Components/All Services Components/All Services Banner/AllServicesBanner";
import ServicesSingleGrid from "../Components/All Services Components/ServicesSingleGrid/ServicesSingleGrid";
import "../assets/style/allservices.css"
const AllServices = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <AllServicesBanner />
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ServicesSingleGrid-main-heading-div">
              <h1>All Services</h1>
              <p>
              Discover a wide range of services tailored to meet your needs.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div>
              <ServicesSingleGrid />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllServices;

import React from "react";
import AllCategoriesBanner from "../../Components/All Categories Components/All Categories Banner/AllCategoriesBanner";
import AllCategoriesSingleGrid from "../../Components/All Categories Components/All Categories Single Grid/AllCategoriesSingleGrid";
import AllServices from "../../Components/All Services Components/All Services Data/AllServicesData";

const CyberSecuritySolutions = () => {
  const bannerPara =
    "Protect your most valuable data and assets with National IT Services' robust cybersecurity solutions. Leverage leading platforms like Trend Micro, ESET, eScan, Fortinet, and more to implement layered defense across endpoints, networks, email, and cloud. Our integrated tools provide advanced threat protection, event correlation, compliance reporting, and real-time monitoring - securing your infrastructure at every level. Managed detection and response services add expert guidance and rapid response. With National IT Services, gain the technology and support to confidently deter attacks, simplify compliance, and enable cyber resilience. Defend against evolving threats with proactive and proven cybersecurity. The future of risk mitigation starts here.";
  const bannerHeading =
    "Secure Your Digital Ecosystem with Robust Cybersecurity";
    const Services = AllServices.filter(service => service.category === "Cyber");
  return (
    <>
      <AllCategoriesBanner
        bannerPara={bannerPara}
        bannerHeading={bannerHeading}
      />
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ServicesSingleGrid-main-heading-div">
              <h1>Cyber Security Solutions</h1>
              <p>
                Discover a wide range of services tailored to meet your needs.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <AllCategoriesSingleGrid Services={Services}/>
          </div>
        </div>
      </div>
    </>
  );
};

export default CyberSecuritySolutions;

import React from "react";
import "./AllCategoriesBanner.css";

const AllCategoriesBanner = ({ bannerPara ,bannerHeading}) => {
  return (
    <>
      <div className="hero-image-all-categories">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="hero-text-all-categories">
                <h1>
                 {bannerHeading}
                </h1>
                <p>
                 {bannerPara}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllCategoriesBanner;

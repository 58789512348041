import React from "react";
import "./C2aComponent.css"; // Import the CSS file
import { Link } from "react-router-dom/cjs/react-router-dom";

const C2aComponent = () => {
  return (
    <div className="c2a-container">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="c2a-content">
              <div className="c2a-text">
                Are you happy with what we offer? Ask for a Free Quote
              </div>
              <div className="c2a-button">
               <Link to="/get-in-touch">
               <button className="btn btn-outline-light btn-md">Ask for Quotation</button>
               </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default C2aComponent;

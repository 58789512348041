import React from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import image1 from "../../assets/carousel/1.png";
import image2 from "../../assets/carousel/2.png";
import image3 from "../../assets/carousel/3.png";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "./TopSlider.css";

const arrowStyles1 = {
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  fontSize: "24px",
  background: "rgba(255, 255, 255, 0.3)",
  color: "#333",
  borderRadius: "50%",
  width: "40px",
  height: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  zIndex: "99999999",
};

const arrowStyles2 = {
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  fontSize: "24px",
  background: "rgba(255, 255, 255, 0.3)",
  color: "#333",
  borderRadius: "50%",
  width: "40px",
  height: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
};

const TopSlider = () => {
  return (
    <div className="threeSliderMian">
      <div className="static-slider3">
        <div style={{ display: "block", margin: "0 auto" }}>
          <Carousel
            showArrows={true}
            showStatus={false}
            showThumbs={false}
            showIndicators={false}
            autoPlay={true}
            interval={3000}
            infiniteLoop={true}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
              hasPrev && (
                <button
                  onClick={onClickHandler}
                  title={label}
                  style={{ ...arrowStyles1, left: "10px" }}
                >
                  <FaChevronLeft style={{ color: "#333", zIndex: "99999" }} />
                </button>
              )
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
              hasNext && (
                <button
                  onClick={onClickHandler}
                  title={label}
                  style={{ ...arrowStyles2, right: "10px" }}
                >
                  <FaChevronRight />
                </button>
              )
            }
          >
            {/* <div style={{ borderRadius: "10px" }}>
              <img src={image2} alt="image2" />
            </div> */}
            <div style={{ borderRadius: "10px" }}>
              <img src={image3} alt="image3" />
            </div>
            <div style={{ borderRadius: "10px" }}>
              <img src={image1} alt="image1" />
            </div>
          </Carousel>
        </div>
      </div>
    </div>
  );
}

export default TopSlider;

import React from "react";
import blogData from "./blog";
import "./HomePageBlog.css";
import DOMPurify from "dompurify";
import { Link } from "react-router-dom";

const HomePageBlog = () => {
  const sortedBlogData = blogData.sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );
  const latestBlogs = sortedBlogData.slice(0, 3);

  return (
    <div>
      <div className="blog-home2 spacer">
        <div>
          <div className="justify-content-center">
            <div className="col-md-12 text-center">
              <h2 className="titlesad">Recent Blogs</h2>
              <h6
                className="m-t-40 m-b-30"
                style={{ marginBottom: "60px", marginTop: "30px" }}
              >
                You can rely on our amazing features list, and our customer
                services will be a great experience for you without a doubt and
                in no time.
              </h6>
            </div>
          </div>
          <div className="container">
            <div className="row">
              {latestBlogs.map((blog) => {
                const titleWords = blog.title.split(' ').length; 
                return (
                  <div key={blog.id} className="col-lg-4 col-md-6 col-sm-12">
                    <div className="card">
                      <Link to={`/blog/${blog.id}`} className="homepageLinkBlog">
                        <img
                          className="card-img-top HomepGaeBlogimg"
                          src={blog.imageSrc}
                          alt="wrappixel kit"
                        />
                      </Link>
                      <div className="date-pos bg-info-gradiant">{blog.date}</div>
                      <h5 className="font-medium homepageTitle">
                        <Link
                          to={`/blog/${blog.id}`}
                          className="homepageLinkBlog"
                        >
                          <h1
                            className="contentBlogsTitle_h"
                            style={{
                              paddingBottom: titleWords > 5 ? "0px" : "25px",
                            }}
                          >
                            {blog.title}
                          </h1>
                        </Link>
                      </h5>

                      <Link to={`/blog/${blog.id}`} className="homepageLinkBlog">
                        <p className="contentBlogs_h">
                          {blog.intro.slice(0, 200)}
                          {blog.intro.length > 200 ? "..." : ""}
                        </p>
                      </Link>
                      <Link to={`/blog/${blog.id}`} className="homepageLinkBlog">
                        <button className="readmore_homepageBlogbutton">
                          {" "}
                          Read More &nbsp;{" "}
                          <i className="fa-solid fa-arrow-right"></i>
                        </button>
                      </Link>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePageBlog;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import blogsData from "../Components/HomePageBlog/blog";
import "../assets/style/blogs.css";

const Blogs = () => {
  const blogsPerPage = 6;
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * blogsPerPage;
  const endIndex = startIndex + blogsPerPage;
  const currentBlogs = blogsData.slice(startIndex, endIndex);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);
  console.log(blogsData);
  return (
    <div>
      <div>
        <div className="blog-page">
          <div className="breadcrumbs">
            <div className="pBD">
              <p className="ppBD">Blogs</p>
            </div>
            <div>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/blogs">Blog</Link>
              </li>
            </div>
          </div>
          <div className="container">
            <div className="row">
              {currentBlogs.map((blog) => {
                const titleWords = blog.title.split(' ').length;
                return (
                  <div className="col-lg-4" key={blog.id}>
                    <Link to={`/blog/${blog.id}`} className="read-more">
                      <div className="card ksadvjhsadvhjscard">
                        <img
                          src={blog.imageSrc}
                          alt="sub"
                          className="allBlogs_image"
                        />
                        <h3
                          className="h3_blogss"
                          style={{
                            paddingBottom: titleWords > 5 ? "0px" : "25px",
                          }}
                        >
                          {blog.title}
                        </h3>
                        {blog.intro.slice(0, 250)}
                        {blog.intro.length > 250 ? "..." : ""}
                        <Link to={`/blog/${blog.id}`} className="read-more">
                          <button className="blog_button">Read More</button>
                        </Link>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="pagination">
        {currentPage > 1 && (
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            className="pagination-button skd"
          >
            <i className="fa fa-angle-left"></i> Prev
          </button>
        )}
        {Array.from(
          { length: Math.ceil(blogsData.length / blogsPerPage) },
          (_, index) => (
            <button
              key={index + 1}
              className={
                index + 1 === currentPage
                  ? "pagination-button active"
                  : "pagination-button"
              }
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </button>
          )
        )}
        {currentPage < Math.ceil(blogsData.length / blogsPerPage) && (
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            className="pagination-button"
          >
            Next <i className="fa fa-angle-right"></i>
          </button>
        )}
      </div>
    </div>
  );
};

export default Blogs;

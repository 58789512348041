import React, { useState, useEffect } from "react";
import "../assets/style/contact.css";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

const GetInTouch = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const handleCloseSnackBar = (event, reason) => {
    setOpen(false);
  };
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };
  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) {
      newErrors.name = "Name is required";
    }
    if (!formData.phone) {
      newErrors.phone = "Phone Number is required";
    }
    if (!formData.email) {
      newErrors.email = "Email is required";
    }
    if (!formData.message) {
      newErrors.message = "Message  is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const apiUrl =
      "http://localhost:8080/functions/api/contact";
    if (validateForm()) {
      setLoading(true);
      fetch(apiUrl, {
        method: "POST",
        body: JSON.stringify(formData),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.status === 200) {
            setOpen(true);
            setFormData({
              name: "",
              phone: "",
              email: "",
              message: "",
            });
            setTimeout(() => {
              setLoading(false);
            }, 1000);
          }
          return response.json();
        })
        .then((data) => {
          console.log("API response:", data);
        })
        .catch((error) => {
          console.error("API error:", error);
        });
    }
  };
  return (
    <>
      <div className="container-fluid mapContainer">
        <div className="row">
          <div className="col-lg-12 ">
            <iframe
              title="Google Map"
              className="gmap_iframe"
              width="100%"
              height="400"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3509.9135262268446!2d77.344221375492!3d28.39167967579718!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cdc36824f338d%3A0x86ae179c0010c4e7!2sNational%20IT%20Services!5e0!3m2!1sen!2sin!4v1698044381151!5m2!1sen!2sin"
            ></iframe>
          </div>
        </div>
      </div>

      <div className="container adsbfrmcon" style={{ padding: "0px" }}>
        <div className="row">
          <div
            className="col-lg-12 headingGetInTouchMainDiv"
            style={{ padding: "0px" }}
          >
            <h1 className="title_git">Get In Touch</h1>
            <p>
              Contact us today for exceptional service. We're ready to assist
              you promptly with tailored solutions,
              <span className="mobSpan">
                {" "}
                <br />
              </span>
              ensuring an outstanding experience unlike any other.
            </p>
          </div>
        </div>
        <br />

        <br />
        <div className="row">
          <div className="col-lg-8">
            <div className="get-in-touch-container">
              <form className="form-container_git" onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="name" className="form-label_git">
                    Name:
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    className="form-input_git"
                  />
                
                </div>
                {errors.name && (
                    <p
                      style={{
                        margin: "0px",
                        padding: "10px 0 0 0",
                        color: "red",
                        fontWeight: "bolder",
                        textAlign: "left",
                        fontSize: "12px",
                      }}
                    >
                      {errors.name}
                    </p>
                  )}
                <div className="form-group">
                  <label htmlFor="email" className="form-label_git">
                    Phone:
                  </label>
                  <input
                    type="text"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    className="form-input_git"
                  />
                  {errors.phone && (
                    <p
                      style={{
                        margin: "0px",
                        padding: "10px 0 0 0",
                        color: "red",
                        fontWeight: "bolder",
                        textAlign: "left",
                        fontSize: "12px",
                      }}
                    >
                      {errors.phone}
                    </p>
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="email" className="form-label_git">
                    Email:
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    className="form-input_git"
                  />
                 {errors.email && (
                    <p
                      style={{
                        margin: "0px",
                        padding: "10px 0 0 0",
                        color: "red",
                        fontWeight: "bolder",
                        textAlign: "left",
                        fontSize: "12px",
                      }}
                    >
                      {errors.email}
                    </p>
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="message" className="form-label_git">
                    Message:
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                    className="form-textarea_git"
                  />
                  {errors.message && (
                    <p
                      style={{
                        margin: "0px",
                        padding: "10px 0 0 0",
                        color: "red",
                        fontWeight: "bolder",
                        textAlign: "left",
                        fontSize: "12px",
                      }}
                    >
                      {errors.message}
                    </p>
                  )}
                </div>

                <button type="submit" className="submit-button_git">
                  {loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Submit"
                  )}
                </button>
              </form>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="getintouchimagecontainer_main shadow-lg">
              <img
                src="https://images.unsplash.com/photo-1499951360447-b19be8fe80f5?auto=format&fit=crop&q=80&w=2940&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                alt="contact us"
                className="img-fluid contactImages"
              />
            </div>
          </div>
        </div>
      </div>

      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          style={{ zIndex: "9999999999999999" }}
          open={open}
          autoHideDuration={2000}
          onClose={handleCloseSnackBar}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MuiAlert
            style={{
              backgroundColor: "green",
              color: "white",
            }}
            onClose={handleCloseSnackBar}
            lg={{ width: "100%" }}
            severity="success"
          >
            Form Submitted Successfully
          </MuiAlert>
        </Snackbar>
      </Stack>
    </>
  );
};

export default GetInTouch;

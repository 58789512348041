import React, { useState } from "react";
import banner from "../../assets/form-banners/banner1/banner-img.png";
import "./FormBanner.css";
import { Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

const FormBanner = () => {
  const [formData, setFormData] = useState({
    email: "",
  });
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const handleCloseSnackBar = (event, reason) => {
    setOpen(false);
  };
  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };
  const validateForm = () => {
    const newErrors = {};
    if (!formData.email) {
      newErrors.email = "Email is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const apiUrl =
    "https://itforsmebackend.netlify.app/.netlify/functions/api/boost";
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        setLoading(true);
        const response = await fetch(apiUrl, {
          method: "POST",
          body: JSON.stringify(formData),
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response.ok) {
          setOpen(true);
          setFormData({
            email: "",
          });
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        } else {
          throw new Error("API request failed");
        }
      } catch (error) {
        console.error(error);
        setOpen(false);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <div>
        <div className="bsda-22">
          <section>
            <div id="banner1" className="banner spacer">
              <div className="container-fluid containersadfBNN">
                <div className="row">
                  <div className="col-lg-5 col-md-7 align-self-center">
                    <h2 className="title font-bold">
                      Give your Business Real boost Now!
                    </h2>
                    <p className="m-t-40 m-b-30">
                      Discover the ideal product from our services kit and
                      catapult your business to new heights. Make informed
                      choices that will fuel your business growth. Select the
                      right products to give your business the real boost it
                      needs. Make smart decisions to enhance your business
                      potential
                    </p>

                    <form onSubmit={handleSubmit}>
                      <input
                        type="email"
                        placeholder="Enter Email Address"
                        id="email"
                        className="font-16"
                        value={formData.email}
                        onChange={handleChange}
                      />

                      {errors.email && (
                        <p
                          style={{
                            margin: "0px",
                            padding: "0px 0 10px 0",
                            color: "red",
                            fontWeight: "bolder",
                            textAlign: "left",
                            fontSize: "12px",
                          }}
                        >
                          {errors.email}
                        </p>
                      )}

                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        className="boostButton"
                      >
                        {loading ? (
                          <CircularProgress size={24} color="inherit" />
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    </form>
                  </div>

                  <div className="col-lg-6 col-md-5 align-self-center ml-auto">
                    <img
                      src={banner}
                      alt="We are Digital Agency"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          style={{ zIndex: "9999999999999999" }}
          open={open}
          autoHideDuration={2000}
          onClose={handleCloseSnackBar}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MuiAlert
            style={{
              backgroundColor: "green",
              color: "white",
            }}
            onClose={handleCloseSnackBar}
            lg={{ width: "100%" }}
            severity="success"
          >
            Email sent successfully
          </MuiAlert>
        </Snackbar>
      </Stack>
    </>
  );
};

export default FormBanner;

import React, { useState } from "react";
import "./offerForm.css";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

function OfferForm() {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
  });
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };
  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) {
      newErrors.name = "Name is required";
    }
    if (!formData.phone) {
      newErrors.phone = "Phone Number is required";
    }
    if (!formData.email) {
      newErrors.email = "Email is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      const apiUrl =
        "https://itforsmebackend.netlify.app/.netlify/functions/api/offers";
      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          body: JSON.stringify(formData),
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (response.ok) {
          setOpen(true);
          setFormData({
            name: "",
            phone: "",
            email: "",
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleCloseSnackBar = (event, reason) => {
    setOpen(false);
  };
  return (
    <>
      <div className="container mainOuterOfferDiv">
        <div className="row">
          <div className="col-lg-12">
            <div className="innerMainOfferDiv">
              <form onSubmit={handleSubmit}>
                <div>
                  <input
                    type="text"
                    id="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    placeholder="Name"
                    className="offerFormInput"
                  />

                  {errors.name && (
                    <p
                      style={{
                        margin: "0px",
                        padding: "0px",
                        color: "red",
                        fontWeight: "bolder",
                        textAlign: "left",
                        fontSize: "12px",
                      }}
                    >
                      {errors.name}
                    </p>
                  )}
                </div>

                <div>
                  <input
                    type="text"
                    id="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    placeholder="Phone"
                    className="offerFormInput"
                  />

                  {errors.phone && (
                    <p
                      style={{
                        margin: "0px",
                        padding: "0px",
                        color: "red",
                        fontWeight: "bolder",
                        textAlign: "left",
                        fontSize: "12px",
                      }}
                    >
                      {errors.phone}
                    </p>
                  )}
                </div>

                <div>
                  <input
                    type="email"
                    id="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    placeholder="Email"
                    className="offerFormInput"
                  />

                  {errors.email && (
                    <p
                      style={{
                        margin: "0px",
                        padding: "0px",
                        color: "red",
                        fontWeight: "bolder",
                        textAlign: "left",
                        fontSize: "12px",
                      }}
                    >
                      {errors.email}
                    </p>
                  )}
                </div>
                <button type="submit" className="offerFormbutton">
                  {loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Get Offer"
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          style={{ zIndex: "9999999999999999" }}
          open={open}
          autoHideDuration={2000}
          onClose={handleCloseSnackBar}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MuiAlert
            style={{
              backgroundColor: "green",
              color: "white",
            }}
            onClose={handleCloseSnackBar}
            lg={{ width: "100%" }}
            severity="success"
          >
            Form Submitted Successfully
          </MuiAlert>
        </Snackbar>
      </Stack>
    </>
  );
}

export default OfferForm;

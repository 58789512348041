import React from "react";
import AllCategoriesBanner from "../../Components/All Categories Components/All Categories Banner/AllCategoriesBanner";
import AllCategoriesSingleGrid from "../../Components/All Categories Components/All Categories Single Grid/AllCategoriesSingleGrid";
import AllServices from "../../Components/All Services Components/All Services Data/AllServicesData";

const HRSoftware = () => {
  const bannerPara =
    "Elevate your HR experience with greytHR's comprehensive suite of solutions. Our Human Resource Management System (HRMS) ensures streamlined and efficient HR operations, simplifying tasks from employee onboarding to centralized information management. Experience hassle-free Payroll Management with accurate and timely payouts, and 100% statutory compliance. Manage employee leaves seamlessly through our Leave Management system, and access real-time attendance data with our Attendance Management System. Align individual and company goals with our Performance Management tools, and enhance communication and engagement with the Employee Portal and Mobile App. greytHR offers an all-in-one solution to meet your HR needs, empowering you to focus on strategic initiatives and drive organizational success.";
  const bannerHeading = "Discover the Power of greytHR: Elevate Your HR Processes";
  const Services = AllServices.filter(service => service.category === "HR");
  return (
    <>
      <AllCategoriesBanner
        bannerPara={bannerPara}
        bannerHeading={bannerHeading}
      />
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ServicesSingleGrid-main-heading-div">
              <h1>HR Software</h1>
              <p>
                Discover a wide range of services tailored to meet your needs.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <AllCategoriesSingleGrid Services={Services}/>
          </div>
        </div>
      </div>
    </>
  );
};

export default HRSoftware;

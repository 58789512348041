import React from "react";
import "./SpecialInfo.css";
import usp_1nits from "../../assets/special/usp_1nits.svg";
import usp_2nits from "../../assets/special/usp_2nits.svg";
import usp_3nits from "../../assets/special/usp_3nits.svg";
import usp_4nits from "../../assets/special/usp_4nits.svg";

const SpecialInfo = () => {
  return (
    <div>
      <div className="container-fluid mainOuetSectionSpecial">
        <div className="row">
          <div className="col-lg-3">
            <div className="main_innerSpec">
            <div className="img_spcontainer">
                <img src={usp_1nits} alt="expert" />
              </div>
              <div className="texxxt_spcontainer">
                <h6>300+</h6>
                <h6> Softwares</h6>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="main_innerSpec">
            <div className="img_spcontainer">
                <img src={usp_2nits} alt="expert" />
              </div>
              <div className="texxxt_spcontainer">
                <h6>Best Price</h6>
                <h6> Guaranteed</h6>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="main_innerSpec">
              <div className="img_spcontainer">
                <img src={usp_3nits} alt="expert" />
              </div>
              <div className="texxxt_spcontainer">
                <h6>Free Expert</h6>
                <h6> Consultation</h6>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="main_innerSpec">
            <div className="img_spcontainer">
                <img src={usp_4nits} alt="expert" />
              </div>
              <div className="texxxt_spcontainer">
                <h6>10 thousands +</h6>
                <h6> Happy Customers</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpecialInfo;

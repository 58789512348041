import React, { useEffect, useState } from "react";
import "./AdPopUp.css";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

const AdPopUp = () => {
  const [formData, setFormData] = useState({
    email: "",
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.email) {
      newErrors.email = "Email is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const apiUrl =
    "https://itforsmebackend.netlify.app/.netlify/functions/api/boost";

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        setLoading(true);
        const response = await fetch(apiUrl, {
          method: "POST",
          body: JSON.stringify(formData),
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response.ok) {
          setSnackbarOpen(true);
          setIsOpen(false);
          setFormData({
            email: "",
          });
          localStorage.setItem("popupCloseTime", new Date().getTime());
        } else {
          throw new Error("API request failed");
        }
      } catch (error) {
        console.error(error);
        setIsOpen(false);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Escape") {
      onClose();
    }
  };

  const onClose = () => {
    setIsOpen(false);
    localStorage.setItem("popupCloseTime", new Date().getTime());
  };

  const handleCloseSnackBar = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    const popupCloseTime = localStorage.getItem("popupCloseTime");
    const currentTime = new Date().getTime();

    if (!popupCloseTime) {
      setTimeout(() => {
        setIsOpen(true);
      }, 1000);
    } else {
      const timeDifference = currentTime - parseInt(popupCloseTime);
      setIsOpen(timeDifference > 1 * 60 * 1000);
    }
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  return (
    <>
      <div
        className={`modal ${isOpen ? "open" : ""}`}
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                <div className="col-md-6 form-section form-section_ad">
                  <div className="xmark_ad_main_div" onClick={onClose}>
                    <i className="fa-solid fa-xmark xmark_add"></i>
                  </div>
                  <h5
                    className="ad_title_h1"
                    style={{
                      background:
                        "radial-gradient(100% 100% at 50% -20%, rgb(255, 82, 82) 0%, rgb(76, 0, 255) 100%)",
                      WebkitBackgroundClip: "text",
                      backgroundClip: "text",
                      color: "transparent",
                      fontSize: "28px",
                      fontWeight: "700",
                    }}
                  >
                    Simple to sign, easy to Send
                  </h5>
                  <p className="ad_title_p">
                    Keep your modern business moving forward from virtually
                    anywhere, at any time.
                  </p>

                  <ul className="adPopUp_ul">
                    <li>
                      {" "}
                      <i className="fa-solid fa-star"></i> &nbsp; ISO 27001
                    </li>
                    <li>
                      {" "}
                      <i className="fa-solid fa-star"></i> &nbsp; PCI Data
                      Security Standard
                    </li>
                    <li>
                      {" "}
                      <i className="fa-solid fa-star"></i> &nbsp; SSAE 18 (SOC 1
                      and 2)
                    </li>
                    <li>
                      {" "}
                      <i className="fa-solid fa-star"></i> &nbsp; CSA STAR
                    </li>
                    <li>
                      {" "}
                      <i className="fa-solid fa-star"></i> &nbsp; APEC PRP
                    </li>
                  </ul>

                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label htmlFor="email" className="ad_email">
                        Company Email
                      </label>
                      <br />
                      <input
                        type="email"
                        className={`form-control ${
                          errors.email ? "is-invalid" : ""
                        }`}
                        id="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="Enter your company email"
                      />
                      {errors.email && (
                        <div className="invalid-feedback">{errors.email}</div>
                      )}
                    </div>
                    <div className="butnDivpop">
                      <button
                        type="submit"
                        className="sendButtonHFormPop_ad"
                        disabled={loading}
                      >
                        {loading ? (
                          <CircularProgress size={24} color="inherit" />
                        ) : (
                          "Send"
                        )}
                      </button>
                    </div>
                  </form>
                </div>
                <div className="col-md-6 background_ad"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          style={{ zIndex: "9999999999999999" }}
          open={snackbarOpen}
          autoHideDuration={2000}
          onClose={handleCloseSnackBar}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MuiAlert
            style={{
              backgroundColor: "green",
              color: "white",
            }}
            onClose={handleCloseSnackBar}
            lg={{ width: "100%" }}
            severity="success"
          >
            Email sent successfully
          </MuiAlert>
        </Snackbar>
      </Stack>
    </>
  );
};

export default AdPopUp;

import React, { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import "./OffersPopUp.css";

const OffersPopUp = ({ product, onClose }) => {
  const [formData, setFormData] = useState({
    phoneNumber: "",
    name: "",
    companyEmail: "",
    productName: "",
  });

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.phoneNumber) {
      newErrors.phoneNumber = "Phone number is required";
    }
    if (!formData.name) {
      newErrors.name = "Name is required";
    }
    if (!formData.companyEmail) {
      newErrors.companyEmail = "Company email is required";
    }
    if (!formData.productName) {
      newErrors.productName = "Product Name is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const apiUrl = "https://itforsmebackend.netlify.app/.netlify/functions/api/sendemail";
    if (validateForm()) {
      setLoading(true);
      fetch(apiUrl, {
        method: "POST",
        body: JSON.stringify(formData),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.status === 200) {
            setOpen(true);
            setFormData({
              phoneNumber: "",
              name: "",
              companyEmail: "",
              productName: "",
            });
            setTimeout(() => {
              setLoading(false);
            }, 1000);
          }
          return response.json();
        })
        .then((data) => {
          console.log("API response:", data);
          onClose();
        })
        .catch((error) => {
          console.error("API error:", error);
        });
    }
  };

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === "Escape") {
        onClose();
      }
    };

    // Set initial form data when the component mounts
    if (product) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        productName: product.title || "",
      }));
    }

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [onClose, product]);

  return (
    <>
      <div className={`modal ${product ? "open" : ""}`} tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header"></div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-6 form-section">
                  {product && (
                    <>
                      <h5 className="modal-title">{product.title}</h5>
                      <form onSubmit={handleSubmit}>
                        <div className="form-group">
                          <label htmlFor="phoneNumber">Phone Number</label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">+91</span>
                            </div>
                            <input
                              type="tel"
                              className={`form-control ${
                                errors.phoneNumber ? "is-invalid" : ""
                              }`}
                              id="phoneNumber"
                              value={formData.phoneNumber}
                              onChange={handleChange}
                              placeholder="Enter your phone number"
                            />
                            {errors.phoneNumber && (
                              <div className="invalid-feedback">
                                {errors.phoneNumber}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="form-group">
                          <label htmlFor="name">Name</label>
                          <input
                            type="text"
                            className={`form-control ${
                              errors.name ? "is-invalid" : ""
                            }`}
                            id="name"
                            value={formData.name}
                            onChange={handleChange}
                            placeholder="Enter your name"
                          />
                          {errors.name && (
                            <div className="invalid-feedback">
                              {errors.name}
                            </div>
                          )}
                        </div>
                        <div className="form-group">
                          <label htmlFor="companyEmail">Company Email</label>
                          <input
                            type="email"
                            className={`form-control ${
                              errors.companyEmail ? "is-invalid" : ""
                            }`}
                            id="companyEmail"
                            value={formData.companyEmail}
                            onChange={handleChange}
                            placeholder="Enter your company email"
                          />
                          {errors.companyEmail && (
                            <div className="invalid-feedback">
                              {errors.companyEmail}
                            </div>
                          )}
                        </div>
                        <div className="form-group">
                          <label htmlFor="productName">Product Name</label>
                          <input
                            type="text"
                            className={`form-control ${
                              errors.productName ? "is-invalid" : ""
                            }`}
                            id="productName"
                            value={formData.productName}
                            onChange={handleChange}
                            placeholder="Enter Product Name "
                          />
                          {errors.productName && (
                            <div className="invalid-feedback">
                              {errors.productName}
                            </div>
                          )}
                        </div>
                        <div className="butnDivpop">
                          <button type="submit" className="sendButtonHFormPop">
                            {loading ? (
                              <CircularProgress size={24} color="inherit" />
                            ) : (
                              "Send"
                            )}
                          </button>
                        </div>
                        <button
                          type="button"
                          className="close-button"
                          onClick={onClose}
                        >
                          &times;
                        </button>
                      </form>
                    </>
                  )}
                </div>
                <div className="col-md-6 background"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          style={{ zIndex: "9999999999999999" }}
          open={open}
          autoHideDuration={2000}
          onClose={handleCloseSnackBar}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MuiAlert
            style={{
              backgroundColor: "green",
              color: "white",
            }}
            onClose={handleCloseSnackBar}
            lg={{ width: "100%" }}
            severity="success"
          >
            Form Submitted Successfully
          </MuiAlert>
        </Snackbar>
      </Stack>
    </>
  );
};

export default OffersPopUp;

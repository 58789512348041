import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./LatestProducts.css";
import products from "./LatestProducts";
import ProductFormPopUp from "../ProductFormPopUp/ProductFormPopUp";


const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  }
};

const LatestProduct = () => {
  const [selectedProduct, setSelectedProduct] = useState(null);

  const handleGetDemoClick = (product) => {
    setSelectedProduct(product);
  };

  const handleClose = () => {
    setSelectedProduct(null);
  };

  return (
    <div>
      <div className="bg-light">
        <div className="textSLID">
          <div className="headingTopS">
            <div className="headingTopInner">
              <h3 className="h3headingTop">Latest Products</h3>
            </div>
          </div>
        </div>
        <div className="container-fluid carousel_container">
          <div>
            <Carousel
              swipeable={false}
              draggable={false}
              showDots={false}
              responsive={responsive}
              ssr={true}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={3000}
              keyBoardControl={true}
              customTransition="transform 1.2s ease"
              transitionDuration={500}
              containerClass="carousel-container slide-animation"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
            >
              {products.map((product, index) => (
                <div key={index}>
                  <div className="container productContainer">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="badge-container shadow-lg">
                          <span className="badge badge-success">Best Seller</span>
                        </div>
                        <div className="product">
                          <div className="product-inside">
                            <div className="image">
                              <img
                                src={product.imageSrc}
                                alt={product.productName}
                                width={250}
                                height={250}
                                className="img-fluid"
                              />
                            </div>
                            <div className="infopro">
                              <h3 className="productName">{product.productName}</h3>
                              <p className="productBy">By {product.productCompany}</p>
                            </div>
                          </div>
                          <div className="rating text-center">
                            <div className="stars">
                              {Array.from({ length: product.rating }).map(
                                (_, i) => (
                                  <i
                                    key={i}
                                    className="fas fa-star filled"
                                  ></i>
                                )
                              )}
                              {Array.from({ length: 5 - product.rating }).map(
                                (_, i) => (
                                  <i key={i} className="fas fa-star"></i>
                                )
                              )}
                            </div>
                            {product.isNewFeature && (
                              <div className="badge badge-primary">New Feature</div>
                            )}
                          </div>
                         
                          {/* <div className="price-cart">
                            <span className="price">Price :</span>
                            <span className="priceName">₹{product.price}</span>
                          </div> */}
                          <hr />
                          <button
                            className="btn btnDemo btn-block"
                            onClick={() => handleGetDemoClick(product)}
                          >
                            Get Demo
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
      <ProductFormPopUp product={selectedProduct} onClose={handleClose} />
    </div>
  );
};

export default LatestProduct;

import React, { useEffect } from "react";
import OfferBanner from "../Components/Offers Components/Offers Banner/OfferBanner";
import OfferForm from "../Components/Offers Components/Offers Form/OfferForm";
import StaticBanner from "../Components/Offers Components/Static Banner/StaticBanner";
import SpecialInfo from "../Components/SpecialInfo/SpecialInfo";
import PopularBrands from "../Components/Offers Components/PopularBrands/PopularBrands";

const Offers = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="container ">
        <div className="row">
          <div className="col-lg-9">
            <OfferBanner />
          </div>
          <div className="col-lg-3">
            <OfferForm />
          </div>
        </div>
      </div>
      <div className="staticMainDiv">
        <StaticBanner />
      </div>
      <PopularBrands />
      <SpecialInfo />
    </>
  );
};

export default Offers;

import React, { useEffect } from "react";
import HeroBanner from "../Components/HeroBanner/HeroBannewr";
import TopSlider from "../Components/TopSlider/TopSlider";
import LatestProduct from "../Components/LatestProducts/LatestProduct";
import TopSellingProducts from "../Components/TopSellingProducts/TopSellingProducts";
import FormBanner from "../Components/FormBanner/FormBanner";
import HomePageBlog from "../Components/HomePageBlog/HomePageBlog";
import C2aComponent from "../Components/C2aComponent/C2aComponent";
import SpecialInfo from "../Components/SpecialInfo/SpecialInfo";
import PartnersNITS from "../Components/Partnets/PartnersNITS";
import AdPopUp from "../Components/AdPopUp/AdPopUp";

const Homepage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <AdPopUp />
      <HeroBanner />
      <LatestProduct />
      <TopSlider />
      <TopSellingProducts />
      <PartnersNITS />
      <FormBanner />
      <SpecialInfo />
      <HomePageBlog />
      <C2aComponent />
    </>
  );
};

export default Homepage;
